import React from 'react';
import { ThemeProvider } from 'styled-components';
import Layout from './components/Layout/Layout';
import { GlobalStyle } from './themes/globalStyles';
import { theme } from './themes/main';
import { WalletProvider } from './utils/providers/wallet/WalletProvider';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <WalletProvider>
        <GlobalStyle />
        <Layout />
      </WalletProvider>
    </ThemeProvider>
  );
}

export default App;
