import React from 'react';
import styled from 'styled-components';
import { typography } from '../../themes/typography';
import { BREAKPOINTS } from '../../utils/consts';
import { baseTheme } from '../../themes/base';

const FAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content */
  width: 80%; /* Set the width to 70% */
  margin: 10px auto 20px auto; /* Center the container with auto margins */
`;

const LinkStyled = styled.a`
  margin-left: 4px;
  font-weight: 600;
  color: ${baseTheme.colors.black};
  text-decoration: underline;
`;

const Title = styled.h1`
  font-family: 'Chantal', sans-serif; /* Use the Chantal font */
  text-align: center; /* Center the text */
  font-size: 60px; /* Set font size to 40px */
  font-weight: 300; /* Medium weight */
  margin: 100px auto 10px auto; /* Center the container with auto margins */
  color: rgb(166, 166, 166); /* Set the text color */
`;

const Question = styled.h3`
  font-family: ${typography.loosFont};
  font-weight: 500; /* Medium weight */
  margin: 10px 0; /* Add some margin for spacing */
`;

const Answer = styled.p`
font-size: ${typography.fontSize.xs};
font-weight: 300;
line-height: 1.66;

@media (min-width: 540px) {
  font-size: ${typography.fontSize.sm};
}

@media (min-width: ${BREAKPOINTS.sm}px) {
  font-size: ${typography.fontSize.xs};
}

@media (min-width: ${BREAKPOINTS.md}px) {
  font-size: ${typography.fontSize.base};
}

@media (min-width: ${BREAKPOINTS.xl}px) {
  font-size: ${typography.fontSize.sm};
}
`;

const FAQ = () => (
  <>
    <Title>FAQ</Title>
    <FAQContainer>
      <Question>What is paintface.pictures all about?</Question>
      <Answer>
        {`PFPs is a collection of 10,000 generative art pieces (Profile Picture NFTs) on
        ApeChain, inspired by the roots of the NFT avatar movement. Each piece is a unique
        combination
        of hand-painted, high-resolution layers meticulously photographed, cut, and reassembled by
        code. It's a celebration of classic PFPs,
        paying homage to legends like CryptoPunks and BAYC
        but keeping it refreshingly simple and true to digital art.`}
      </Answer>

      <Question>Is this really &quot;art&quot; or yet another NFT collection?</Question>
      <Answer>
        {`paintface.pictures combines the best of both worlds. We see it as part of the generative art
        movement, but it is also a throwback to the roots of PFP mania — the NFTs
        we use as avatars to express
        ourselves online. It is crafted from the mixture of IRL effort mixed 
        with digital mechanism that is so often used in all kinds of NFTs. 
        Throwback to to the early days of
        PFPs, but with our unique hand-painted style.`}
      </Answer>

      <Question>What makes each piece unique?</Question>
      <Answer>
        {`The collection is built from 222 unique hand-painted layers, separated into 3 attributes - left eye,
        right eye, and face. Each edition has its own personality — some pieces feel more coherent and
        harmonious, while others are wilder and abstract. We believe that everyone will find something
        cool and uniquely them in this collection.`}
      </Answer>

      <Question>Why ApeChain?</Question>
      <Answer>
        {`ApeChain has quickly become the go-to place for NFT collectors and digital art lovers, with
        a strong community that values PFPs. The vibe here is just awesome - all about celebrating art
        & creativity in a way that
        represents the personality and style of Web3 culture. Launching paintface.pictures on
        could only happen on ApeChain.`}
      </Answer>

      <Question>Why now?</Question>
      <Answer>
        {`We kept this project ready for more than 2 years, holding back until we
        felt the time was right. After 3+ years of working in and watching
        the industry evolve, we’re now fully confident that both the time is right,
        and that we are ready to make this happen. With ApeChain’s successful
        launch and the Ape community vibe, we know it’s time to go all-in
        and make this happen.`}
      </Answer>

      <Question>What’s the mint price?</Question>
      <Answer>
        {`Each PFP is priced at 10 APE, with a maximum mint limit of 42 per wallet.
        We wanted to make the price accessible, so more people can dive in, get multiple pieces
        and find the one that resonates with them. Also - they super cool in bundles.`}
      </Answer>

      <Question>10k * 10 $APE = 100k $APE — what’s the plan?</Question>
      <Answer>
        {`Actually, a bit under 97k, as we’re reserving 333 special pieces.
        But fair question! When we sell out, this will be the largest
        primary sale on ApeChain so far. We’re reinvesting to do some
        cool things on ApeChain and IRL. Expect paintface.pictures to
        be showcased at Web3 conferences, art fairs, and global events —
        bridging the gap between degens and the traditional art world.
        We want to show that blockchain isn’t just about memes; it’s
        about real creativity and art too. This funding brings ideas we’ve
        dreamed about into reality & is fuel for the future success of this project.`}
      </Answer>

      <Question>What are you doing with the 333 special editions?</Question>
      <Answer>
        {`We’re reserving 333 pieces with mono-eyes as a special set for team members, people that help
        with the launch, art lovers, KOLs, giveaways, 
        potential future auctions, and some surprise activations.
        Some of them will also be distributed to wallets who mint maximum allowed amount, top hodlers & more.
        These special pieces will stand out
        from the rest of the collection to be easily verifiable.
        Looks rare? That might make them more or less valuable over time, depending on how the community and
        collectors value them. These pieces add a touch of exclusivity and mystery to the collection,
        making them ideal for future gallery exhibitions.`}
      </Answer>

      <Question>What can I expect in the future?</Question>
      <Answer>
        {`We have some exciting things planned, but we are keeping the details under wraps for now.
        Think
        of it as part of the fun — this journey will reveal itself over time. Just know that
        paintface.pictures is here to stay, and we are committed to
        keeping the collection fresh and
        relevant in the NFT space. Let the paint dry a bit & we'll keep on buidling.
        If you want to get some alpha, we'll be in Bangkok at DevCon -
        would love to hear your thoughts, seek info on X.`}
      </Answer>

      <Question>Are you going to launch a token?</Question>
      <Answer>
        {`We’ll see — not right away & not through ApeExpress
        but we’re open to the idea when the timing,
        purpose, community size and execution are perfect.
        We have a few ideas of how to do it right, but don’t want to tease you here.
        Although be sure that ff it happens, it’ll be distributed for
        hodlers of PFPs only. So if you’re thinking of positioning yourself for it,
        consider getting a few PFPs, mint as many as you can, sweep the floor,
        support us on socials & let your friends know about the project. We’ll be 
        monitoring both on-chain & off-chain activity.
        You never know when a snapshot might come & what will be the reward.`}
      </Answer>

      <Question>How do I mint paintface.pictures NFT?</Question>
      <Answer>
        {`Once we are live, you will be able to mint directly from this website. Make sure you have a wallet with
        `}
        <LinkStyled
          href="https://apechain.com/portal"
          target="_blank"
          rel="noopener noreferrer"
        >
          enough APE
        </LinkStyled>
        {` to cover the mint price (10 APE per piece). When we approach mint,
        just connect your wallet, and you will be able to mint your paintface.pictures
        in no time.`}
        <br />
        Alternatively, you can mint through the
        <LinkStyled
          href="https://magiceden.io/nft-mints/apechain"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mint Terminal on MagicEden
        </LinkStyled>
        .
      </Answer>

      <Question>Why should I care about paintface.pictures?</Question>
      <Answer>
        {`If you love digital art, generative collections & are tired with derrivatives and non-original 
        projects or simply want some NFTs that capture the early spirit of PFPs, paintface.pictures is for you. 
        PFPs, paintface.pictures is for you. Each NFT is hand-crafted and one-of-a-kind, made for art
        lovers and collectors who appreciate creativity and uniqueness. Plus, it is a fresh, simple
        take in a world of overcomplicated NFTs — this collection speaks for itself.`}
      </Answer>

      <Question>Is there a community behind paintface.pictures?</Question>
      <Answer>
        {`Absolutely. We are building a community of art lovers, digital collectors, and people who
        appreciate a good PFP. Join us on this journey, vibe with the community, and enjoy being part
        of something unique and authentic in the ApeChain ecosystem.`}
      </Answer>

      <Question>Who is behind the project?</Question>
      <Answer>
        {`A swift team of three renegades — a visionary, an artist, and a
        developer. We’re staying undoxed for now, but may reveal our
        identities down the line. You'll meet us at conferences or when we slide in your DMs. 
        When you get to know us, keep it private. Being
        in the know makes you rare or even legendary — why spoil that?`}
      </Answer>

      <Question>Where can I stay updated?</Question>
      <Answer>
        {`Follow us on X and put the notifications on.
        We'll be sharing updates,
        hosting giveaways, and giving sneak peeks at what is coming next.
        This is a long-term project,
        and we would love for you to be part of it.`}
      </Answer>

      <Question>WEN mint?</Question>
      <Answer>
        {`11/11. The exact hour will be announced on our X.
        After the mint, join us in Bangkok during DevCon week to spread the
        paintface vibes with the crypto world. We’ll have stickers,
        merch & some other fun stuff to celebrate launch/sell-out. LFP!`}
      </Answer>
    </FAQContainer>
  </>
);

export default FAQ;
