import React from 'react';
import { baseTheme } from 'src/themes/base';
import styled from 'styled-components';
import { ContentText } from '../../common/components';
import { BREAKPOINTS } from '../../utils/consts';

const FooterStyled = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;

  @media (min-width: ${BREAKPOINTS.sm}px) {
    flex-direction: row;
    align-items: center;
  }
`;

const FooterLink = styled.a`
  font-size: 18px;
  color: ${baseTheme.colors.quicksilver};
  transition: all 0.1s;
  text-decoration: none;

  &:hover {
    color: ${baseTheme.colors.satinBlack};
    text-decoration: underline;
  }
`;

const LinksGrid = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: auto auto auto;
  order: 1;

  @media (min-width: ${BREAKPOINTS.sm}px) {
    order: 2;
  }
`;

const Copyrights = styled.div`
  display: flex;
  opacity: 0.25;
  align-items: center;
  order: 2;
  margin-top: 16px;

  @media (min-width: ${BREAKPOINTS.sm}px) {
    margin-top: 0;
    order: 1
  }
`;

const Footer = () => (
  <FooterStyled>
    <Copyrights>
      <ContentText mr="12px">
        {new Date().getFullYear()}
        {' '}
        &copy;
      </ContentText>
      <img
        src="/images/hero-logo.svg"
        alt="Paintface pictures"
        height="20px"
      />
    </Copyrights>
    <LinksGrid>
      <FooterLink
        target="_blank"
        href="mailto:gm@paintface.pictures"
      >
        gm@paintface.pictures
      </FooterLink>
      <FooterLink
        target="_blank"
        href="https://apescan.io/"
      >
        Smart Contract
      </FooterLink>
      <FooterLink
        target="_blank"
        href="https://x.com/PaintFacePics"
      >
        X profile
      </FooterLink>
    </LinksGrid>
  </FooterStyled>
);

export default Footer;
