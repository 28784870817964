import React, {
  FC, useCallback, useEffect,
  useMemo,
  useState,
} from 'react';
import { ethers } from 'ethers';
import {
  WalletContext,
} from './WalletContext';

export const WalletProvider: FC = ({
  children,
}) => {
  const [
    signer,
    setSigner,
  ] = useState<ethers.providers.JsonRpcSigner | null>(null);

  const onSetAccount = (accounts: Array<string>) => {
    if (accounts[0]) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      setSigner(provider.getSigner());
    }
  };

  const onInit = useCallback(async () => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const accounts = await provider.listAccounts();

      if (accounts.length) {
        setSigner(provider.getSigner());
      }
    }
  }, []);

  useEffect(
    () => {
      if (window.ethereum) {
        window.ethereum.on('accountsChanged', onSetAccount);
      }
    },
    [setSigner]
  );

  useEffect(() => {
    onInit();
  }, [onInit]);

  const value = useMemo(
    () => ({
      signer,
      setSigner,
    }),
    [signer]
  );

  return (
    <WalletContext.Provider value={value}>
      {children}
    </WalletContext.Provider>
  );
};
