import React from 'react';
import styled from 'styled-components';
import { typography } from '../../themes/typography';
import { baseTheme } from '../../themes/base';
import { BREAKPOINTS } from '../../utils/consts';

const BoxStyled = styled.div`
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(400% + 3px);
  height: calc(200% + 1px);
  padding: ${baseTheme.spacing.large} ${baseTheme.spacing.medium};
  background-color: #ececec;
  box-sizing: border-box;
`;

const DescStyled = styled.p`
  font-size: ${typography.fontSize.xs};
  font-weight: 300;
  line-height: 1.66;

  @media (min-width: 540px) {
    font-size: ${typography.fontSize.sm};
  }

  @media (min-width: ${BREAKPOINTS.sm}px) {
    font-size: ${typography.fontSize.xs};
  }

  @media (min-width: ${BREAKPOINTS.md}px) {
    font-size: ${typography.fontSize.base};
  }

  @media (min-width: ${BREAKPOINTS.xl}px) {
    font-size: ${typography.fontSize.sm};
  }
`;

const SecondarySpanStyled = styled.span`
  font-family: ${typography.chantalFont};
`;

const LinkStyled = styled.a`
  margin-left: 4px;
  font-weight: 600;
  color: ${baseTheme.colors.black};
  text-decoration: underline;
`;

const LogoStyled = styled.img`
  width: 65%;
  @media (min-width: ${BREAKPOINTS.md}px) {
    font-size: 1em;
  }
`;

export const MainDescGrid = () => (
  <BoxStyled>
    <LogoStyled
      src="/images/hero-logo.svg"
      alt="Paintface pictures"
    />
    <DescStyled>
      A collection of 10,000 NFT art pieces on ApeChain.
      Each Paintface Picture is unique, but together they are one.
      <LinkStyled
        href="https://x.com/PaintFacePics"
        target="_blank"
      >
        Join the  movement
      </LinkStyled>
      {' '}
      & become a
      {' '}
      <SecondarySpanStyled>paintface</SecondarySpanStyled>
      .
    </DescStyled>
  </BoxStyled>
);
