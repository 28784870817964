import styled from 'styled-components';
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system';

type FlexProps = FlexboxProps & LayoutProps & {
  direction?: 'column' | 'row';
}

interface GridBoxProps {
  columns?: number;
  gridGap?: string;
}

export const CenterBox = styled.div<FlexProps & SpaceProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: center;
  align-items: center;
  ${flexbox}
  ${layout}
  ${space}
`;

export const FlexRow = styled.div<FlexProps & SpaceProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${flexbox}
  ${space}
`;

export const FlexColumn = styled.div<FlexProps & SpaceProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${flexbox}
  ${layout}
  ${space}
`;

export const GridBox = styled.div<GridBoxProps & SpaceProps>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  grid-gap: ${({ gridGap }) => gridGap || '16px'};
  ${space}
`;
