import styled from 'styled-components';
import {
  space,
  color,
  typography,
  ColorProps,
  SpaceProps,
  TypographyProps,
} from 'styled-system';

type ContentTextProps = TypographyProps & SpaceProps & ColorProps;

export const ContentText = styled.span<ContentTextProps>`
  white-space: nowrap;
  ${space};
  ${typography};
  ${color};
`;
