import React, {
  FC, ReactNode, useCallback, useEffect, useRef, useState,
} from 'react';
import { getNumberInRange } from 'src/utils/helpers';
import styled, { css } from 'styled-components';
import { baseTheme } from '../../themes/base';

interface PaintFaceProps {
    active: boolean;
    splash: boolean;
    shouldFadeOut: boolean;
    xSpan: number;
    ySpan: number;
    currentPFP: number;
    setNextPFP: React.Dispatch<React.SetStateAction<number>>;
    erased?: number;
    renders?: ReactNode;
}

interface PfBoxProps {
    active: boolean;
    splash: boolean;
    xSpan: number;
    ySpan: number;
}

const PAINT_FACE_PREFIX = 'pfp_p_';
const TOTAL_PFPS = 211;

const PaintFaceStyled = styled.div<PfBoxProps>`
  position: relative;
  box-sizing: border-box;
  visibility: hidden;
  ${({ active }) => active && css`
    visibility: visible;
    outline: 1px solid ${baseTheme.colors.quicksilver};
  `}
  animation-play-state: paused;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  ${({ splash }) => splash && css`
    animation: splash 1s ease-in-out;
    animation-play-state: running;
  `}

  @keyframes splash {
    0% {
      outline: 1px solid ${baseTheme.colors.quicksilver};
    }
    50% {
      outline: 1px solid ${baseTheme.colors.clearBlue};
    }
    100% {
      outline: 1px solid ${baseTheme.colors.quicksilver};
    }
  }
`;

const PaintFaceContentStyled = styled.div<{ bgSrc?: string; bgActive?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 2s;

  ${({ bgActive }) => bgActive && css`
    opacity: 1;
    transition: opacity 0.25s;
  `}

  ${({ bgSrc }) => bgSrc && css`
    background-image: url(${bgSrc});
    background-position: center center;
    background-size: 100% auto;
    background-repeat: no-repeat;
  `};
`;

const shuffleBackground = (index: number): string => `/images/faces/${PAINT_FACE_PREFIX}${index}.jpg`;

const PaintFaceElement: FC<PaintFaceProps> = ({
  active,
  splash,
  erased,
  shouldFadeOut,
  renders,
  xSpan,
  ySpan,
  currentPFP,
  setNextPFP,
}) => {
  const pfRef = useRef<HTMLDivElement>(null);
  const [
    bgSrc,
    setBgSrc,
  ] = useState<string>('');
  const [
    bgActive,
    setBgActive,
  ] = useState(false);

  useEffect(() => {
    if (erased) {
      const timeToFadeOut = getNumberInRange(1, 10) * 100;

      setTimeout(() => {
        setBgActive(false);
      }, timeToFadeOut);
    }
  }, [erased]);

  const onMouseOver = useCallback(() => {
    setNextPFP(currentPFP === TOTAL_PFPS ? 1 : currentPFP + 1);
    setBgSrc(shuffleBackground(currentPFP));
    setBgActive(true);
  }, [
    currentPFP,
    setNextPFP,
  ]);
  const onMouseOut = useCallback(() => {
    setTimeout(() => {
      setBgActive(false);
    }, 1000);
  }, []);

  useEffect(() => {
    const pfRefCurrent = pfRef.current;

    if (pfRefCurrent && active) {
      pfRefCurrent.addEventListener('mouseenter', onMouseOver);

      if (shouldFadeOut) {
        pfRefCurrent.addEventListener('mouseleave', onMouseOut);
      }
    }

    return () => {
      if (pfRefCurrent) {
        pfRefCurrent.removeEventListener('mouseenter', onMouseOver);
        pfRefCurrent.removeEventListener('mouseleave', onMouseOut);
      }
    };
  }, [
    active,
    onMouseOut,
    onMouseOver,
    shouldFadeOut,
  ]);

  return (
    <PaintFaceStyled
      splash={splash}
      active={active}
      ref={pfRef}
      xSpan={xSpan}
      ySpan={ySpan}
    >
      {renders && renders}
      <PaintFaceContentStyled
        bgSrc={bgSrc}
        bgActive={bgActive}
      />
    </PaintFaceStyled>
  );
};

export default PaintFaceElement;
