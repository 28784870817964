export const typography = {
  fontFamily: '"proxima-nova", sans-serif',
  chantalFont: '"chantal", sans-serif',
  loosFont: '"loos-extrawide", sans-serif',
  fontSize: {
    base: '16px',
    mds: '18px',
    lg: '28px',
    md: '24px',
    sm: '20px',
    m: '22px',
    xlg: '40px',
    xmd: '32px',
    xs: '14px',
    xxs: '12px',
    xxxs: '10px',
  },
  fontsWeight: {
    bold: '700',
    semiBold: '600',
    medium: '500',
    regular: '400',
    light: '300',
  },
};

