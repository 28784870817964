export const BREAKPOINTS = {
  xs: 376,
  s: 640,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1920,
};

export const MEDIA_QUERIES = {
  mobile: `(max-width: ${BREAKPOINTS.sm}px)`,
  tablet: `(min-width: ${BREAKPOINTS.sm}px)`,
  desktop: `(min-width: ${BREAKPOINTS.md}px)`,
  desktopMedium: `(min-width: ${BREAKPOINTS.lg}px)`,
  desktopLarge: `(min-width: ${BREAKPOINTS.xl}px)`,
};

export const VALID_EMAIL = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$/;
