import {
  useEffect, useState,
} from 'react';
import { MEDIA_QUERIES } from '../consts';

export const useMediaQuery = (query: string) => {
  const [
    matches,
    setMatches,
  ] = useState(false);

  useEffect(
    () => {
      const mediaQuery = window.matchMedia(query);

      setMatches(mediaQuery.matches);
      const handler = (event: MediaQueryListEvent) => setMatches(event.matches);

      mediaQuery.addEventListener('change', handler);

      return () => mediaQuery.removeEventListener('change', handler);
    },
    [query]
  );

  return matches;
};

export const useBreakpoints = () => ({
  isMobile: useMediaQuery(MEDIA_QUERIES.mobile),
  isTablet: useMediaQuery(MEDIA_QUERIES.tablet),
  isDesktop: useMediaQuery(MEDIA_QUERIES.desktop),
  isDesktopMedium: useMediaQuery(MEDIA_QUERIES.desktopMedium),
  isDesktopLarge: useMediaQuery(MEDIA_QUERIES.desktopLarge),
});
