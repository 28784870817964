import React, {
  useContext, useEffect, useReducer, useState,
} from 'react';
import styled, { css } from 'styled-components';
import {
  Contract, ethers,
} from 'ethers';
import { WalletContext } from '../../utils/providers/wallet/WalletContext';
import { baseTheme } from '../../themes/base';
import {
  FlexColumn, FlexRow,
} from '../../common/components/Layout';
import { ContentText } from '../../common/components';
import { typography } from '../../themes/typography';
import ABI from './contractABI.json';
import { ButtonWithLink } from '../ButtonWithLink/ButtonWithLink';
import { RangeSelector } from '../RangeSelector/RangeSelector';
import { MintingStatus } from './MintingStatus';

const PFP_PRICE = 10;

const WhiteListMintStyled = styled.div`
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: calc(300% + 2px);
  height: calc(200% + 1px);
  padding: ${baseTheme.spacing.regular} ${baseTheme.spacing.normal};
  background-color: ${baseTheme.colors.satinBlack};
`;

const mintButtonBGs = ({ inActive }: { inActive: boolean }) => (inActive ?
  css`
  background-color: ${baseTheme.colors.silver};
` :
  css`
  background-color: ${baseTheme.colors.mayaGreen};

  :disabled {
    background-color: ${baseTheme.colors.silver};
  }

  &:hover:not(:disabled) {
    background-color: ${baseTheme.colors.hanumanGreen};
    color: ${baseTheme.colors.satinBlack};
  }
`);

const MintButtonStyled = styled.button<{ inActive: boolean }>`
  position: absolute;
  bottom: 0;
  left: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: calc(33% + 2px);
  max-width: calc(33% + 2px);
  height: 50%;
  padding: ${baseTheme.spacing.normal} ${baseTheme.spacing.small};
  transition: all .1s;
  text-align: left;
  color: ${baseTheme.colors.snowWhite};

  &:disabled {
    cursor: auto;
  }

  ${mintButtonBGs}
`;

const MintedSummaryText = styled(ContentText)`
  font-size: ${typography.fontSize.m};
  font-family: ${typography.chantalFont};
  font-weight: ${typography.fontsWeight.light};
`;

export interface TransactionState {
  tx: string | null;
  status: string;
  error?: string;
}

export interface ContractDetails {
  capacity?: string;
  totalMinted?: string;
  maxMintAmount?: string;
}

const initialState: TransactionState = {
  tx: null,
  status: '',
};

type Action =
  | { type: 'request'; payload: { tx: string } }
  | { type: 'success'; payload: { tx: string } }
  | { type: 'failed'; payload: { error: string } };

function reducer(state: TransactionState, action: Action) {
  switch (action.type) {
    case 'request':
      return {
        tx: action.payload.tx,
        status: 'pending',
      };
    case 'success':
      return {
        ...state,
        status: 'success',
      };
    case 'failed':
      return {
        ...state,
        status: 'failed',
        error: action.payload.error,
      };
    default:
      return initialState;
  }
}

function mapTransactionError(code?: string): string {
  switch (code) {
    case 'INSUFFICIENT_FUNDS':
      return 'Insufficient funds';
    case 'MAX_REACHED':
      return 'Enough for you already!';
    default:
      return 'Transaction failed';
  }
}

export const Minting = () => {
  const {
    signer,
  } = useContext(WalletContext);
  const [
    contractStatus,
    setContractStatus,
  ] = useState<ContractDetails>({});
  const [
    rangeValue,
    setRangeValue,
  ] = useState(42);
  const [
    state,
    dispatch,
  ] = useReducer(reducer, initialState);

  const fetchContractStatus = async (contract: Contract) => {
    const total = await contract.functions.totalSupply();
    const max = await contract.functions.maxSupply();
    const maxMintAmount = await contract.functions.maxMintAmount();

    setContractStatus({
      totalMinted: total.toString(),
      capacity: max.toString(),
      maxMintAmount: maxMintAmount.toString(),
    });
  };

  useEffect(() => {
    if (signer) {
      const provider = new ethers.providers.JsonRpcProvider('https://rpc.curtis.apechain.com', 33111);
      const contract = new ethers.Contract('0xb8ae86278b6c6Ec376F6C18450C16e7ffB317e0c', ABI, provider);

      fetchContractStatus(contract);
    }
  }, [signer]);

  const onMint = async () => {
    if (!window.ethereum) {
      return;
    }

    try {
      if (signer) {
        const contract = new ethers.Contract('0xb8ae86278b6c6Ec376F6C18450C16e7ffB317e0c', ABI, signer);
        const provider = new ethers.providers.JsonRpcProvider('https://rpc.curtis.apechain.com', 33111);

        const transaction = await contract.functions.mint(rangeValue, {
          value: ethers.utils.parseEther(`${rangeValue * PFP_PRICE}`),
        });

        dispatch({
          type: 'request',
          payload: { tx: transaction.hash },
        });

        provider.once(transaction.hash, tx => {
          dispatch({
            type: 'success',
            payload: { tx: tx.hash },
          });
        });
      } else {
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        await window.ethereum.request({ method: 'eth_requestAccounts' });

        const contract = new ethers.Contract('0xb8ae86278b6c6Ec376F6C18450C16e7ffB317e0c', ABI, provider.getSigner());

        await contract.functions.claim(1, {
          value: ethers.utils.parseEther(`${rangeValue * PFP_PRICE}`),
        });
      }
    } catch (error) {
      if (error.code) {
        dispatch({
          type: 'failed',
          payload: { error: mapTransactionError(error.code) },
        });
      } else {
        dispatch({
          type: 'failed',
          payload: { error: mapTransactionError() },
        });
      }
    }
  };

  return (
    <WhiteListMintStyled>
      <FlexColumn>
        <FlexRow
          mt={baseTheme.spacing.regular}
          justifyContent="center"
        >
          <ContentText
            fontSize={typography.fontSize.xmd}
            fontFamily={typography.loosFont}
            color={baseTheme.colors.snowWhite}
          >
            PFP
          </ContentText>
          <ContentText
            fontSize={typography.fontSize.xmd}
            fontFamily={typography.loosFont}
            color={baseTheme.colors.hanumanGreen}
          >
            .minting
          </ContentText>
        </FlexRow>
      </FlexColumn>
      <RangeSelector
        value={rangeValue}
        onChange={value => setRangeValue(value)}
      />
      <FlexRow
        mt={baseTheme.spacing.regular}
        justifyContent="center"
      >
        <MintedSummaryText
          color={baseTheme.colors.hanumanGreen}
          mr={baseTheme.spacing.small}
        >
          pfps minted
        </MintedSummaryText>
        <MintedSummaryText
          color={baseTheme.colors.quicksilver}
          mr={baseTheme.spacing.small}
        >
          so far:
        </MintedSummaryText>
        <MintedSummaryText
          color={baseTheme.colors.hanumanGreen}
          mr={baseTheme.spacing.small}
        >
          {contractStatus.totalMinted}
        </MintedSummaryText>
        <MintedSummaryText
          color={baseTheme.colors.quicksilver}
        >
          /
          {' '}
          {contractStatus.capacity}
        </MintedSummaryText>
      </FlexRow>
      <ButtonWithLink>
        <MintButtonStyled
          onClick={onMint}
          inActive={!window.ethereum}
          disabled={!contractStatus.maxMintAmount}
        >
          <ContentText
            fontSize={typography.fontSize.m}
            fontFamily={typography.loosFont}
          >
            mint
          </ContentText>
          <FlexRow alignItems="flex-end">
            <ContentText
              fontSize={typography.fontSize.xlg}
              fontFamily={typography.loosFont}
            >
              {rangeValue}
            </ContentText>
            <FlexColumn>
              <ContentText
                fontSize={typography.fontSize.xxs}
                fontFamily={typography.loosFont}
                ml={baseTheme.spacing.xsmall}
              >
                {rangeValue > 1 ? 'PFPs' : 'PFP'}
              </ContentText>
              {contractStatus.maxMintAmount && (
                <ContentText
                  fontSize={typography.fontSize.base}
                  fontFamily={typography.loosFont}
                >
                  /
                  {(+contractStatus.maxMintAmount) - rangeValue}
                </ContentText>
              )}
            </FlexColumn>
          </FlexRow>
          <ContentText
            fontSize={typography.fontSize.xs}
            fontFamily={typography.chantalFont}
            fontWeight={typography.fontsWeight.light}
          >
            {PFP_PRICE * rangeValue}
            {' '}
            $APE
          </ContentText>
        </MintButtonStyled>
      </ButtonWithLink>
      {state.status && (
        <MintingStatus transaction={state} />
      )}
    </WhiteListMintStyled>
  );
};

// export default Minting;
