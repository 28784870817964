import {
  Context,
  createContext,
  Dispatch,
  SetStateAction,
} from 'react';
import { ethers } from 'ethers';

export type WalletContextProps = {
  signer?: ethers.providers.JsonRpcSigner | null;
  setSigner?: Dispatch<SetStateAction<ethers.providers.JsonRpcSigner | null>>;
}

export const WalletContext: Context<WalletContextProps> =
  createContext({});
