import React from 'react';
import styled, { css } from 'styled-components';

interface GridImageProps {
    src: string;
    sizeX: number;
    sizeY: number;
    zIndex: number;
}

const FigureStyled = styled.figure<Omit<GridImageProps, 'src'>>`
  z-index: ${({ zIndex }) => zIndex};
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  box-sizing: border-box;

  ${({
    sizeX, sizeY,
  }) => css`
    width: calc(${sizeX * 100}% + ${sizeX}px);
    height: calc(${sizeY * 100}% + ${sizeY}px);
  `}

  img {
    display: block;
    width: 100%;
  }
`;

export const GridImage = ({
  src,
  ...props
}: GridImageProps) => (
  <FigureStyled {...props}>
    <img
      srcSet={src}
      alt="Paint faces"
    />
  </FigureStyled>
);
