export const baseTheme = {
  backgroundColor: '#E5E5E5',
  colors: {
    // black
    black: '#000',
    satinBlack: '#1C1E21',
    // gray
    coronation: '#ECECEC',
    quicksilver: '#a6a6a6',
    silver: '#C4C4C4',
    doveGray: '#6c6c6c',
    // green
    hanumanGreen: '#4DFFB4',
    accentGreen: '#1C8168',
    mayaGreen: '#9ad0d8',
    niagaraMist: '#C6EAEF',
    // white
    snowWhite: '#fff',
    bleachedSilk: '#f2f2f2',
    // blue
    oceanBlue: '#00A0C3',
    aeroBlue: '#C3FFE6',
    clearBlue: '#247CFF',
    // status
    errorColor: '#FF3D00',
    successColor: '#1C8168',
    chardon: '#F8EBDF',
  },
  radius: {
    xxsmall: '4px',
    xsmall: '8px',
    medium: '16px',
    regular: '24px',
    large: '32px',
    full: '100%',
  },
  spacing: {
    micro: '2px',
    xsmall: '4px',
    small: '8px',
    normal: '12px',
    regular: '16px',
    medium: '24px',
    mediumx2: '48px',
    big: '32px',
    large: '40px',
    bigx2: '64px',
  },
};

