import React, { FC } from 'react';

export const ButtonWithLink: FC = ({ children }) => (!window.ethereum ?
  (
    <a
      href="https://metamask.io"
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  ) :
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{ children }</>);
