import React from 'react';
import { BREAKPOINTS } from 'src/utils/consts';
import styled from 'styled-components';
import { baseTheme } from '../../themes/base';

const GridSocialLinkStyled = styled.a`
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: ${baseTheme.spacing.regular};
  background-color: ${baseTheme.colors.satinBlack};
  color: ${baseTheme.colors.bleachedSilk};
  border: none;
  cursor: pointer;
  transition: all .1s;

  &:hover {
    background-color: ${baseTheme.colors.hanumanGreen};
    color: ${baseTheme.colors.satinBlack};
  }
`;

const SocialImage = styled.img`
  width: 60%;
  @media (min-width: ${BREAKPOINTS.sm}px) {
    width: auto;
  }
`;

export const GridSocialLink = ({
  url, img,
}: { url: string; img: string }) => (
  <GridSocialLinkStyled
    href={url}
    target="_blank"
  >
    <SocialImage
      src={img}
      alt="join.pfp"
    />
  </GridSocialLinkStyled>
);
