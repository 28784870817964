import React from 'react';
import styled from 'styled-components';
import { Range } from 'react-range';
import { FlexColumn } from 'src/common/components/Layout';
import { baseTheme } from '../../themes/base';
import { typography } from '../../themes/typography';
import { ContentText } from '../../common/components';

interface RangeSelectorProps {
  value: number;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: number) => void;
}

const RangeWrapperStyled = styled.div<{ trackSrc: string }>`
  display: flex;
  width: 100%;
  padding: 0 ${baseTheme.spacing.regular};
  background-image: url(${({ trackSrc }) => trackSrc})
`;

const RangeMark = ({
  index, active,
}: { index: number; active: boolean }) => (
  <FlexColumn mt="-4px">
    <img
      src="/images/range-mark.png"
      alt="Mint"
    />
    <ContentText
      fontSize={active ? typography.fontSize.lg : typography.fontSize.md}
      fontFamily={typography.chantalFont}
      fontWeight={typography.fontsWeight.light}
      color={active ? baseTheme.colors.coronation : baseTheme.colors.quicksilver}
      mt={baseTheme.spacing.small}
      ml="-4px"
    >
      {index + 1}
    </ContentText>
  </FlexColumn>
);

// eslint-disable-next-line no-unused-vars
export const RangeSelector = ({
  onChange,
  value,
}: RangeSelectorProps) => (
  <RangeWrapperStyled trackSrc="/images/range-track.png">
    <Range
      step={1}
      min={1}
      max={42}
      values={[value]}
      onChange={values => onChange(values[0])}
      renderTrack={({
        props, children,
      }) => (
        <div
          {...props}
          style={{
            // eslint-disable-next-line react/prop-types
            ...props.style,
            height: '6px',
            width: '100%',
            backgroundColor: 'transparent',
          }}
        >
          {children}
        </div>
      )}
      renderMark={({
        props, index,
      }) => (
        <div
          {...props}
          style={{
            // eslint-disable-next-line react/prop-types
            ...props.style,
            height: '8px',
            width: '3px',
            backgroundColor: 'transparent',
          }}
        >
          {((index + 1) % 10 === 0 || index === 0) && (
          <RangeMark
            index={index}
            active={index + 1 === value}
          />
          )}
        </div>
      )}
      renderThumb={({ props }) => (
        <div
          {...props}
          style={{
            // eslint-disable-next-line react/prop-types
            ...props.style,
            height: '48px',
            width: '48px',
            backgroundColor: 'transparent',
            outline: 'none',
          }}
        >
          <img
            src="/images/range-thumb.png"
            alt="Mint"
            style={{
              display: 'block',
              width: '100%',
            }}
          />
        </div>
      )}
    />
  </RangeWrapperStyled>
);
