import React from 'react';
import styled from 'styled-components';
import {
  FlexRow,
} from '../../common/components/Layout';
import { ContentText } from '../../common/components';
import { typography } from '../../themes/typography';
import { baseTheme } from '../../themes/base';
import { TransactionState } from './Minting';

const MintStatusStyled = styled.div<{ isError: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  justify-content: space-between;
  width: calc(133% + 1px);
  height: 50%;
  padding: ${baseTheme.spacing.medium};
  background-color: ${({ isError }) => (isError ? baseTheme.colors.chardon : baseTheme.colors.bleachedSilk)};
`;

const StatusIconStyled = styled.img`
  display: block;
  width: 50px;
`;

const ErrorInfoIconStyled = styled.img`
  display: block;
  width: 20px;
  margin-right: ${baseTheme.spacing.normal};
`;

const TxLinkStyled = styled.a`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: ${typography.fontFamily};
  font-size: ${typography.fontSize.base};
  color: ${baseTheme.colors.quicksilver};
`;

const TxLabelStyled = styled(ContentText)`
  margin-right: ${baseTheme.spacing.small};
  font-family: ${typography.chantalFont};
  font-size: ${typography.fontSize.m};
  color: ${baseTheme.colors.quicksilver};
  white-space: nowrap;
`;

function getStatusColor(status: string) {
  switch (status) {
    case 'success':
      return baseTheme.colors.successColor;
    case 'failed':
      return baseTheme.colors.errorColor;
    default:
      return baseTheme.colors.satinBlack;
  }
}

export const MintingStatus = ({ transaction }: { transaction: TransactionState }) => (
  <MintStatusStyled isError={transaction.status === 'failed'}>
    <FlexRow
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <ContentText
        fontFamily={typography.chantalFont}
        fontWeight={typography.fontsWeight.light}
        fontSize={typography.fontSize.m}
        color={baseTheme.colors.satinBlack}
      >
        transaction
        <strong>.status:</strong>
      </ContentText>
      <FlexRow
        alignItems="center"
        flex={0}
      >
        <StatusIconStyled
          src={`/images/status-${transaction.status}.png`}
          alt={transaction.status}
        />
        <ContentText
          fontFamily={typography.loosFont}
          fontSize={typography.fontSize.m}
          color={getStatusColor(transaction.status)}
        >
          {transaction.status}
        </ContentText>
      </FlexRow>
    </FlexRow>
    {transaction.status !== 'failed' && transaction.tx && (
      <FlexRow justifyContent="space-between">
        <TxLabelStyled>tx #:</TxLabelStyled>
        <TxLinkStyled
          href={`${process.env.REACT_APP_ETHERSCAN_URI}/tx/${transaction.tx}`}
          target="_blank"
          rel="noreferrer"
        >
          {transaction.tx}
        </TxLinkStyled>
      </FlexRow>
    )}
    {transaction.error && (
      <FlexRow alignItems="center">
        <ErrorInfoIconStyled src="/images/error-info.png" />
        <ContentText
          fontFamily={typography.fontFamily}
          fontSize={typography.fontSize.sm}
          color={baseTheme.colors.errorColor}
        >
          <strong>{transaction.error}</strong>
        </ContentText>
      </FlexRow>
    )}
  </MintStatusStyled>
);
